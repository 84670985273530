/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import {  MainContainer, SubContainer,Main, LeftArrow, Input, Section, Image } from "../components/Styles";
import LeftArrowIcon from '../assets/images/left-arrow.svg';
import emailIcon from '../assets/images/email.svg';
import passwordIcon from '../assets/images/password.svg';
import eyeClose from '../assets/images/eye-close.svg';
import eyeOpen from '../assets/images/eye-open.svg';
import { Background } from "../components/Background";
import { useNavigate } from "react-router-dom";
import { DefaultButton } from "../components/DefaultButton";
import { Paragraph, H1, LinkText } from "../components/Text";
import userApi from "../utils/userApi";
import { storeUserData, getUserData } from "../utils/authUtils";

function Login() {
    const navigate = useNavigate();
    const [error, setError] = React.useState("")
    const [success, setSuccess] = React.useState("")
    const [showPassword, setShowPassword] = React.useState(false)
    const [formData, setFormData] = React.useState({
        email: "",
        password: ""
    });    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = () => {
        const params = {
            email: formData.email,
            password: formData.password,
        }
        userApi.loginApi(params).then((res) => {
            // console.log(res);
            if (res.status === true) {
                // console.log(userID);
                setSuccess("Login Succesfully.")
                setTimeout(() => {
                    const accessToken = res.message.access_token
                    storeUserData(accessToken);
                    navigate("/home");
                })
            } else {
                setError(res.error);
                // console.log(res.error);
            }
        });
    };   
    const forgetPassword =()  => {        
        navigate("/forgot-password", { state: { type: "forget" } } )
    }
    const back = () => {
        navigate("/welcome")
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && formData.email && formData.password) {
            handleSubmit();
        }
    };

    React.useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [formData]);
    React.useEffect(() => {
        const user = getUserData()
        // console.log(user, "user===========")
        if(user) {
            navigate("/home"); 
        }
    }, []);

    return (
        <MainContainer>
            <Background />
            <SubContainer >
                <Main className="padding-25">
                    <Main onClick={back}><LeftArrow src={LeftArrowIcon} className="mb-10" /></Main>
                    <H1 white="true" className="mb-0 letter-spacing">Login</H1>
                    <Paragraph gray="true" className="fontNormal mt-10 mb-10">Use your email & password</Paragraph>

                    <Section className="mb-20">
                        <Section className="relative">
                            <Image src={emailIcon} className="absolute w-auto top-25 left-20" />
                            <Input className="inputBg mt-10 fontMedium" placeholder="Email" name="email"
                                value={formData.email}
                                onChange={handleChange} 
                                autoComplete="off"/>
                        </Section>
                        <Section className="relative">
                            <Image src={passwordIcon} className="absolute w-auto top-25 left-20" />
                            <Input className="inputBg mt-10 fontMedium fontRegular password-right" placeholder="Password" name="password"
                                value={formData.password}
                                onChange={handleChange} 
                                type={showPassword ? "text" : "password"} 
                                autoComplete="off"/>
                            <Image src={showPassword ? eyeOpen : eyeClose} className="absolute w-auto top-25 right-20" onClick={() => setShowPassword(!showPassword)}/>
                        </Section>
                        <Section>
                            {error !== "" && (
                                <Paragraph red="true">
                                    {error}
                                </Paragraph>
                            )}
                            {success !== "" && (
                                <Paragraph green="true">
                                    {success}
                                </Paragraph>
                            )}
                        </Section>
                    </Section>

                    <Main className="fontNormal mb-10 inline-block text-decoration-none" onClick={forgetPassword}>
                        <LinkText white="true">Forgot Password?</LinkText>
                    </Main>

                </Main>

                <DefaultButton onClick={handleSubmit}  disabled={!(formData.email && formData.password)}  className={`absolute left-20 right-20 bottom-20 w-auto ${!(formData.email && formData.password) ? "disable" : ""} `}>Login</DefaultButton>
               
            </SubContainer>
        </MainContainer>
    );
}

export default Login;
