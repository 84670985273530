import { BrowserRouter } from 'react-router-dom';
import CommonRoutes from './Routes.js';
import { ToastContainer } from "react-toastify"

function App() {
  return (
    <BrowserRouter>
      <CommonRoutes />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
