// authUtils.js
import { jwtDecode } from "jwt-decode"

// Function to store user data in local storage
export const storeUserData = (userData) => {
  localStorage.setItem('DA_userData', JSON.stringify(userData));
};

export const storeOTP = (otp) => {
  localStorage.setItem('otp', JSON.stringify(otp));
};
export const getOTP = () => {
  const otp = localStorage.getItem('otp');
  return otp ? JSON.parse(otp) : null;
};

// Function to retrieve user data from local storage
export const getUserData = () => {
  const userData = localStorage.getItem('DA_userData');
  return userData ? JSON.parse(userData) : null;
};

// Function to get userId from token
export const getUserId = () => {
  const userData = localStorage.getItem('DA_userData');
  if(userData) {
    const userDetails = jwtDecode(userData)
    return userDetails.user_id ? userDetails.user_id: null
  } else {
    return null
  }
};


// Function to clear user data from local storage
export const clearUserData = () => {
  localStorage.removeItem('DA_userData');
};
