import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CommonHelper = {    
    dateFormattor: (rawDate, formatTo) => {
        const date = moment(rawDate)
        return date.format(formatTo)
    },

    showError: (type, message) => {
        // console.log(type, message, "messagew=========")
        const option = {
            autoClose: 3000,
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
        }
        toast[type](message,option)
        
    }
}