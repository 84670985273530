import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, Image, Input } from "../components/Styles";
import { Background } from "../components/Background";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OnboardingStarIcon from '../assets/images/onboarding_star.svg';
import SearchLargeIcon from '../assets/images/searchlarge.svg';
import KnowMoreIcon from '../assets/images/knowmore.svg';
import FirstInviteIcon from '../assets/images/first_invite.jpg';
import CaretRightIcon from '../assets/images/caretright.svg';
import EventsOneIcon from '../assets/images/eventsone.jpg';
import TopBarImIcon from '../assets/images/top_bar_im.svg';
import ArticleImg from '../assets/images/article_img.png';
import { H5, H6, Paragraph, LinkText } from "../components/Text";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Usher from '../assets/images/news_large.svg';


function Location() {
  const navigate = useNavigate();
  const detail = () => {    
    navigate("/articles")
  }

//   var settings = {
//     dots: false,
//     centerMode: true,
//     centerPadding: '25px',
//     arrows: false,
//     slidesToShow: 2,
//     slidesToScroll: 1
// };
    return (
        <MainContainer>
         <Background/>
         <SubContainer className="pt-80">

          <Header EventPoints={true} settingIcon={true} />

           <Main className="padding-25 pt-0">                      

            <Section>
             <Section className="relative mt-0 mb-20">
              <Image src={SearchLargeIcon} className="absolute w-auto top-22 left-14" />
              <Input className="inputBg mt-10 fontRegular full_lg_search" placeholder="Search" />
             </Section>
             <Section className="displayblock">
              <Background className="bar_invite pl-14 pt-10 pb-10 overflowhidden radius-4">
               <Section className="">
                <H6 white="true" className="mt-0 mb-10">JBJ’S Bar Invite</H6>
                <Section className="mb-30">
                 <Paragraph white="true" className="opacity-50 max-170 mt-0">Buy tickets to the JBJ event that’s coming on 26 November.</Paragraph>
                </Section>
                <LinkText white="true">Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
               </Section>
               <Section className="bar_vw_prof absolute right-0 top-0">
                <Image className="wd-139 ht-185 bar_prof" src={FirstInviteIcon} />
               </Section> 
              </Background>
             </Section>
             <Section className="displayflex content-between items-center mt-15">
              <H5 white="true" className="mt-0 mb-0 opacity-50 uppercase">EVENTS near you</H5>
              <LinkText><Image src={CaretRightIcon} /></LinkText>
             </Section>

             {/* <Slider className="mt-15 sets mb-15" {...settings}> */}
              <Section className="relative max-265" onClick={() => navigate("/news")}>
               <Image className="absolute top-0 zindex left-12" src={TopBarImIcon} /> 
               
               <Background className="darkin">
                <H5 white="true" className="mt-0 mb-0 pl-15 pr-15 pt-32">The Bon Jovi experience</H5>
                <Paragraph white="true" className="opacity-60 pl-15 pr-15">Starting Sat, 15 <span className="displayblock">June United Kingdom</span></Paragraph>
                <Image className="mt-10" src={EventsOneIcon} />
                <Section className="absolute bottom-0">
                 <Background className="lightin displayflex content-between items-center"> 
                  <Paragraph white="true" className="mt-0 pl-15 opacity-50"><Image src={OnboardingStarIcon} /> 50 points</Paragraph>
                  <LinkText className="mr-15" white="true" onClick={() => navigate("/news")}>Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
                 </Background> 
                </Section>
               </Background>
              </Section>
              
              {/* <Section className="relative max-265" onClick={() => navigate("/news")}>
               <Image className="absolute top-0 zindex left-12" src={TopBarImIcon} /> 
               <Background className="darkin">
                <H5 white="true" className="mt-0 mb-0 pl-15 pr-15 pt-32">The Bon Jovi experience</H5>
                <Paragraph white="true" className="opacity-60 pl-15 pr-15">Starting Sat, 15 <span className="displayblock">June United Kingdom</span></Paragraph>
                <Image className="mt-10" src={EventsOneIcon} />
                <Section className="absolute bottom-0">
                 <Background className="lightin displayflex content-between items-center"> 
                  <Paragraph white="true" className="mt-0 pl-15 opacity-50"><Image src={OnboardingStarIcon} /> 50 coins</Paragraph>
                  <LinkText className="mr-15" white="true" onClick={() => navigate("/news")}>Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
                 </Background> 
                </Section>
               </Background>
              </Section>
              
              <Section className="relative max-265" onClick={() => navigate("/news")}>
               <Image className="absolute top-0 zindex left-12" src={TopBarImIcon} /> 
               <Background className="darkin">
                <H5 white="true" className="mt-0 mb-0 pl-15 pr-15 pt-32">The Bon Jovi experience</H5>
                <Paragraph white="true" className="opacity-60 pl-15 pr-15">Starting Sat, 15 <span className="displayblock">June United Kingdom</span></Paragraph>
                <Image className="mt-10" src={EventsOneIcon} />
                <Section className="absolute bottom-0">
                 <Background className="lightin displayflex content-between items-center"> 
                  <Paragraph white="true" className="mt-0 pl-15 opacity-50"><Image src={OnboardingStarIcon} /> 50 coins</Paragraph>
                  <LinkText className="mr-15" white="true" onClick={() => navigate("/news")}>Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
                 </Background> 
                </Section>
               </Background>
              </Section> */}

             {/* </Slider> */}

             <Section className="displayflex content-between items-center">
              <H5 white="true" className="mt-0 mb-0 opacity-50 uppercase">Articles</H5>
              <LinkText><Image src={CaretRightIcon} /></LinkText>
             </Section>


             <Section className="mb-160">
              <ul className="p-0 mb-0">
               
               
               <li className="block mb-15 relative">
                <Section className="articles_bg">
                 <Image className="radius-4 w-full radius-4 block" src={ArticleImg} />
                 <Background className="articles_bg_in" />
                </Section> 
                <Section className="absolute left-0 top-0 h-full zindex" onClick={detail}>
                 <Section className="article_in displayflex flex-column content-between h-full"> 
                  <Section className="w-in-auto">
                   <H5 white="true" className="fontSemiBold mt-10 mb-20 max-250">Bon Jovi announces a new artifact collection</H5>
                   <H6 lightwhite="true" className="mt-10 mb-10 opacity-70 fontRegular font-Regular">Read about Bon Jovi’s latest.</H6>
                  </Section>
                  <Section className="w-in-auto">
                   <LinkText white="true">Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
                  </Section> 
                 </Section> 
                </Section>
               </li>

               <li className="block mb-15 relative">
                <Section className="articles_bg">
                 <Image className="radius-4 w-full radius-4 block" src={Usher} />
                 <Background className="articles_bg_in" />
                </Section> 
                <Section className="absolute left-0 top-0 h-full zindex" onClick={detail}>
                 <Section className="article_in displayflex flex-column content-between h-full"> 
                  <Section className="w-in-auto">
                   <H5 white="true" className="fontSemiBold mt-10 mb-20 max-250">Coming Soon</H5>
                  </Section>
                  <Section className="w-in-auto">
                   {/* <LinkText white="true">Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText> */}
                  </Section> 
                 </Section> 
                </Section>
               </li>

{/* 

               <li className="block mb-15 relative">
                <Section className="articles_bg">
                 <Image className="radius-4 w-full radius-4 block" src={ArticleImg} />
                 <Background className="articles_bg_in" />
                </Section> 
                <Section className="absolute left-0 top-0 h-full zindex" onClick={detail}>
                 <Section className="article_in displayflex flex-column content-between h-full"> 
                  <Section className="w-in-auto">
                   <H5 white="true" className="fontSemiBold mt-10 mb-20 max-250">Bon Jovi announces a new artifact collection</H5>
                   <H6 lightwhite="true" className="mt-10 mb-10 opacity-70 fontRegular font-Regular">Read about Bon Jovi’s latest.</H6>
                  </Section>
                  <Section className="w-in-auto">
                   <LinkText white="true">Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
                  </Section> 
                 </Section> 
                </Section>
               </li>


               <li className="block mb-15 relative">
                <Section className="articles_bg">
                 <Image className="radius-4 w-full radius-4 block" src={ArticleImg} />
                 <Background className="articles_bg_in" />
                </Section> 
                <Section className="absolute left-0 top-0 h-full zindex" onClick={detail}>
                 <Section className="article_in displayflex flex-column content-between h-full"> 
                  <Section className="w-in-auto">
                   <H5 white="true" className="fontSemiBold mt-10 mb-20 max-250">Bon Jovi announces a new artifact collection</H5>
                   <H6 lightwhite="true" className="mt-10 mb-10 opacity-70 fontRegular font-Regular">Read about Bon Jovi’s latest.</H6>
                  </Section>
                  <Section className="w-in-auto">
                   <LinkText white="true">Know more <Image className="ml-5" src={KnowMoreIcon} /></LinkText>
                  </Section> 
                 </Section> 
                </Section>
               </li> */}


              </ul>


             </Section>
             <Section className="fixed bottom-0 left-0 right-0 max-590 m-auto zindex">
              <Section onClick={() => navigate("/dashbash")} className="relative w-85 block m-auto bottom-70" >
               <Background className="seem_works radius-4">
                <Paragraph white="true" className="mt-0 max-300 fontRegular font-Regular pt-12 pl-12 pr-12 dash_bash"><span className="opacity-70">You seem to be near</span> ‘Dash Bash - New York' <span className="opacity-70">- there's a way to win</span> 100 points.</Paragraph>
                <LinkText><Image className="pt-12 pb-12 pl-12 pr-12" src={CaretRightIcon} /></LinkText>
               </Background>
              </Section>
              <Section>  

                <Footer />            
              
               {/* <Background className="bottom_all_menu"> 
                <ul className="mb-0 mt-0 displayflex content-between">
                 <li className="block text-center wd-33">
                  <LinkText white="true" className="bottom_menu"  onClick={() => navigate("/updates")}>
                   <Background className="bottom_in_update_active"><Image className="wd-20" src={NewsImg} /></Background><span className="displayblock fontBold mt-5 pb-10">Updates</span><span className="displayblock borderBottom-2 wd-75 ht-2 m-auto"></span>
                  </LinkText>
                 </li>
                 <li className="block text-center wd-33">
                  <LinkText white="true" className="bottom_menu" onClick={() => navigate("/vault")} >
                   <Background className="bottom_in_update"><Image className="wd-20 opacity-40" src={VaultImg} /></Background><span className="displayblock fontRegular font-Regular opacity-40 mt-5 pb-5">Vault</span>
                  </LinkText>
                 </li>
                 <li className="block text-center wd-33">
                  <LinkText white="true" onClick={() => navigate("/profile")}>
                   <Background className="bottom_in_update"><Image className="wd-20 opacity-40" src={ProfileIconImg} /></Background><span className="displayblock fontRegular font-Regular opacity-40 mt-5 pb-5">Profile</span>
                  </LinkText>
                 </li>
                </ul>
               </Background> */}

              </Section>
             </Section>
            </Section>
           </Main>
          </SubContainer>
        </MainContainer>
    );
    }

export default Location;