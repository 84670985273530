/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import {  MainContainer, SubContainer,Main, LeftArrow, Input, Section, Image } from "../components/Styles";
import { Link } from "react-router-dom";
import LeftArrowIcon from '../assets/images/left-arrow.svg';
import emailIcon from '../assets/images/email.svg';

import { Background } from "../components/Background";
import { DefaultButton } from "../components/DefaultButton";
import { Paragraph, H1 } from "../components/Text";
import { useNavigate } from "react-router-dom";
import userApi from '../../src/utils/userApi'
import { CommonHelper } from '../utils/helper'

function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("")
    const [validEmail, setValidEmail] = React.useState(false)
    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
        if (emailRegex.test(value)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }
    const handleClick = () => {
        const params = {
            "email" : email
        }
        userApi.forgetPassword(params)
        .then((response)=>{
            // console.log(response, "response========")
            if(response.status === false){
                CommonHelper.showError("error",response.error)
            }else{
                CommonHelper.showError("success",response.message.success)
            }
            
        })
        .catch((err)=>{
            console.log(err, "err==========")
            CommonHelper.showError("error", err.message || "An error occurred while sending the request")
        })
        // navigate('/new-password', {
        //     state: { 
        //         from: "forget"
        //     }
        // });  
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && validEmail) {
            handleClick();
        }
    };

    React.useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [validEmail]);
    return (
        <MainContainer>
            <Background/>
            <SubContainer >
                <Main className="padding-25">
                    <Link to="/login">
                        <LeftArrow src={LeftArrowIcon} className="mb-10" />
                    </Link>

                        <H1 white="true" className="mb-0 letter-spacing">Forgot your password?</H1>
                        <Paragraph gray="true" className="fontNormal mt-10 mb-10">Type your email & we’ll send you a verification link</Paragraph>

                    <Section className="mb-20">
                        <Section className="relative">
                            <Image src={emailIcon} className="absolute w-auto top-25 left-20" />
                            <Input className="inputBg mt-10 fontMedium" placeholder="Email" value={email} onChange={(e) => {
                                        handleChange(e)
                                    }}  />
                        </Section>
                    </Section>
                </Main>

                        <DefaultButton 
                        className={`absolute left-20 right-20 bottom-20 w-auto ${validEmail ? "" : "disable"}`}
                        onClick={handleClick}
                        disabled={!validEmail} >Send Confirmation</DefaultButton>
            </SubContainer>
        </MainContainer>
    );
}

export default ForgotPassword;
