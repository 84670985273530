/* eslint-disable no-unused-vars */

import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import styled from "styled-components";
import { H5, Paragraph } from "../components/Text";
import { Background } from "../components/Background";
import { DefaultButton } from "../components/DefaultButton";
import DefinitiveLogoIcon from '../assets/images/definitive_logo.svg';
import { getUserData } from "../utils/authUtils";

import Image1 from "../assets/images/image1.png"
import Image2 from "../assets/images/image2.png"
import Image3 from "../assets/images/image3.png"
import Image4 from "../assets/images/image4.png"
import Image5 from "../assets/images/image5.png"
import Image6 from "../assets/images/image6.png"


const WelcomeContainer = styled.div`
    background:var(--bg-vip-ultra);
`;

function WelcomeOne() {
    const navigate = useNavigate();
    // const [selectedDate, setSelectedDate] = React.useState("")
    const [activeIndex, setActiveIndex] = React.useState(0);
    // const handleDateChange = (date) => {
    //     if (date) {
    //         let savingDate = new Date(date);
    //         savingDate.setHours(23, 59, 0, 0);
    //         setSelectedDate(savingDate);
    //     } else {
    //         setSelectedDate(null);
    //     }
    // }

    const handleItemClick = (index) => {
        setActiveIndex(index);
    };
    React.useEffect(() => {
        const user = getUserData()
        // console.log(user, "user===========")
        if(user) {
            navigate("/home"); 
        }
    }, []);
    return (
        <MainContainer>
            <Background />
            <SubContainer>
                <Main className="padding-25 relative">
                <Section className="text-center relative">
                    <Image src={DefinitiveLogoIcon} />
                </Section>

                        <>
                            <Main className={`${activeIndex === 0 ? "block" : "none"}`}>
                                <Section className="relative">
                                    <H5 white="true" className="text-center letter-spacing-2 break-spaces">A 21st century fan experience</H5>
                                    <Main className="mt-10 mb-10 text-center letter-spcaing">
                                        <Paragraph gray="true" className="break-spaces">Level up, get unparalleled access</Paragraph>
                                    </Main>
                                </Section>

                                <WelcomeContainer className="flex h-20vh overflow-x pb-20">
                                    <Main className="column">
                                        <Image className=" h-[100px] object-fit: cover w-100%" src={Image5} />
                                        <Image className=" h-[100px] object-fit: cover w-100%" src={Image3} />
                                        <Image className=" h-[100px] object-fit: cover w-100%" src={Image1} />
                                    </Main>

                                    <Main className="column">
                                        <Image className=" h-[100px] object-fit: cover w-100%" src={Image2} />
                                        <Image className=" h-[100px] object-fit: cover w-100%" src={Image4} />
                                        <Image className=" h-[100px] object-fit: cover w-100%" src={Image6} />
                                    </Main>
                                </WelcomeContainer>
                            </Main>
                        </>



                    <Main className="flex w-auto bottom-20 left-0 right-0 max-590 m-auto mt-20">
                        <DefaultButton className="text-small-base skipin custom_bottom rl-5" onClick={() => navigate("/login")}>Login</DefaultButton>
                        <DefaultButton className="mb-10 text-small-base custom_bottom ml-5" onClick={() => navigate("/signup")}>Signup</DefaultButton>
                    </Main>
                </Main>
            </SubContainer>
        </MainContainer>
    );
}

export default WelcomeOne;