/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { MainContainer, SubContainer, Main, LeftArrow, Input, Section, Image } from "../components/Styles";
import { Link } from "react-router-dom";
import LeftArrowIcon from '../assets/images/left-arrow.svg';
import eyeClose from '../assets/images/eye-close.svg';
import eyeOpen from '../assets/images/eye-open.svg';
import GrayTick from '../assets/images/gray-tick.svg';
import GreenTick from '../assets/images/green-tick.svg';
import PasswordChange from '../assets/images/password-change.svg';
import { DefaultButton } from "../components/DefaultButton";
import { H1, H3, Paragraph } from "../components/Text";
import { Background } from "../components/Background";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import userApi from "../utils/userApi";
import { storeUserData, clearUserData, storeOTP, getOTP } from "../utils/authUtils.js"
import { CommonHelper } from "../utils/helper";

function NewPassword() {
    const location = useLocation();
    const { from } = location.state || {};
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [passwordCriteria, setPasswordCriteria] = React.useState({
        length: false,
        upper: false,
        lower: false,
        number: false,
        match: false
    });
    const [loginView, setLoginView] = React.useState(false);
    const [error, setError] = React.useState("");
    const navigate = useNavigate();

    const handleChangePassword = (e) => {
        const value = e.target.value;
        setPassword(value);
        validatePassword(value, confirmPassword);
    };

    const handleChangeConfirmPassword = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        validatePassword(password, value);
    };

    const validatePassword = (password, confirmPassword) => {
        setPasswordCriteria({
            length: password.length >= 8,
            upper: /[A-Z]/.test(password),
            lower: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            match: password && confirmPassword ? password === confirmPassword : false
        });
    };

    const submit = async (e) => {
        const { from, email } = location.state || {};
        if (passwordCriteria.length &&
            passwordCriteria.upper &&
            passwordCriteria.lower &&
            passwordCriteria.number &&
            passwordCriteria.match) {
            e.preventDefault();
            if (from === "signup") {
                const params = {
                    email,
                    password,
                }
                userApi.signupApi(params).then((res) => {
                    // console.log(res);
                    if (res.status) {
                        const accessToken = res.message.access_token
                        storeUserData(accessToken);
                        setLoginView(true);
                    } else {
                        setError(res.error);
                    }
                });
            } else {
                console.log("elsee===============")
                const otp = getOTP() 
                console.log(otp, "otp==========")
                const params = {
                    password: password,
                    otp: otp
                };
                userApi.updatePassword(params)
                    .then((response) => {
                        console.log(response, "response from new password");                        
                        if(response.status === false ) {
                            CommonHelper.showError("error", response.error)
                        }else{
                            CommonHelper.showError("success", "Password has beed updated")
                            setLoginView(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err, "err from new password");                        
                        const errorMessage = err.response && err.response.data && err.response.data.error
                            ? err.response.data.error
                            : err.message || "An unknown error occurred.";
                        CommonHelper.showError("error", errorMessage);
                    });
                
            }
        }


    };

    const login = () => {
        if (loginView && from == "signup"){
            navigate("/home"); 
        }else{
            clearUserData() 
            setTimeout(() => {            
                navigate("/login"); 
            }, 1000);
        }
        
    };
    const handleKeyPress = (event) => {
        const { from } = location.state || {};
        console.log(from, "from====================")
        if (event.key === 'Enter') {
            if (loginView && from != "signup" ) {
                login();
            } else if (loginView && from == "signup"){
                navigate("/home"); 
            }
            else {
                submit(event);
            }
        }
    };


    React.useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [loginView, password, confirmPassword, passwordCriteria]);

    React.useEffect(() => {
        console.log(from, location, "from===============")
        const queryParams = new URLSearchParams(location.search);
        
        
        
        if(queryParams) {
            // Get the token and OTP
            const token = queryParams.get('token');
            const otp = queryParams.get('otp');

            console.log("Token:", token);
            console.log("OTP:", otp);
            // const token = queryParams.split('=')[1];
            console.log("insideeeee iff tokenn==========")
            storeUserData(token);
            storeOTP(otp)
        }
    }, []);

    return (
        <MainContainer>
            <Background />
            <SubContainer >
                {!loginView && (
                    <form onSubmit={submit}>
                        <Main className="padding-25">
                            <Link to={`${from === "signup" ? "/signup" : "/forgot-password"}`}><LeftArrow src={LeftArrowIcon} className="mb-10" /></Link>
                            <H1 white="true" className="mb-0 letter-spacing">{from === "signup" ? "Create a password" : "Create a new password"}</H1>
                            <Paragraph gray="true" className="gray fontNormal mt-10 mb-10">{from === "signup" ? "Follow the guidelines below" : "Follow the guidelines below"}</Paragraph>
                            <Section>
                                {error !== "" && (
                                    <Paragraph red="true">
                                        {error}
                                    </Paragraph>
                                )}
                            </Section>
                            <Section className="mb-20">
                                <Section className="relative">
                                    <Input autoComplete="off" name="password" className="input-no-pad password-right inputBg mt-10 fontMedium fontRegular pl-10" placeholder="New Password" type={showPassword ? "text" : "password"} value={password} onChange={(e) => handleChangePassword(e)} />
                                    <Image src={showPassword ? eyeOpen : eyeClose} className="absolute w-auto top-25 right-20" onClick={() => setShowPassword(!showPassword)} />
                                </Section>
                                
                                <Section className="relative">
                                    <Input autoComplete="off" name="confirmPassword" className="input-no-pad password-right inputBg mt-10 fontMedium fontRegular pl-10" placeholder="Confirm a new password" type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => handleChangeConfirmPassword(e)} />
                                    <Image src={showConfirmPassword ? eyeOpen : eyeClose} className="absolute w-auto top-25 right-20" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                </Section>
                            </Section>

                            <Section>
                                <Paragraph green={passwordCriteria.length.toString()} gray={!passwordCriteria.length ? "true" : "false"} className="fontLight">
                                    <Image src={passwordCriteria.length ? GreenTick : GrayTick} className="w-auto" /> At least 8 characters
                                </Paragraph>

                                <Paragraph green={passwordCriteria.upper.toString()} gray={!passwordCriteria.upper ? "true" : "false"} className="fontLight">
                                    <Image src={passwordCriteria.upper ? GreenTick : GrayTick} className="w-auto" /> At least 1 upper case letter (A-Z)
                                </Paragraph>

                                <Paragraph green={passwordCriteria.lower.toString()} gray={!passwordCriteria.lower ? "true" : "false"} className="fontLight">
                                    <Image src={passwordCriteria.lower ? GreenTick : GrayTick} className="w-auto" /> At least 1 lower case letter (a-z)
                                </Paragraph>

                                <Paragraph green={passwordCriteria.number.toString()} gray={!passwordCriteria.number ? "true" : "false"} className="fontLight">
                                    <Image src={passwordCriteria.number ? GreenTick : GrayTick} className="w-auto" /> At least 1 number (0-9)
                                </Paragraph>

                                <Paragraph green={passwordCriteria.match.toString()} gray={!passwordCriteria.match ? "true" : "false"} className="fontLight">
                                    <Image src={passwordCriteria.match ? GreenTick : GrayTick} className="w-auto" /> Make sure the passwords match
                                </Paragraph>

                            </Section>

                            <DefaultButton type="submit" className={`absolute left-20 right-20 bottom-20 w-auto ${!(
                                passwordCriteria.length &&
                                passwordCriteria.upper &&
                                passwordCriteria.lower &&
                                passwordCriteria.number &&
                                passwordCriteria.match
                            ) ? "disable" : ""}`} disabled={!(
                                passwordCriteria.length &&
                                passwordCriteria.upper &&
                                passwordCriteria.lower &&
                                passwordCriteria.number &&
                                passwordCriteria.match
                            )}>Continue</DefaultButton>
                        </Main>
                    </form>
                )}

                {loginView && (
                    <>
                        <Main className="padding-25 text-center flex item-center content-center flex-column">
                            <Image src={PasswordChange} className="passwordChangeLogo block" />
                            <Section>
                                <H3 white="true" className="mb-10 inline-block text-decoration-none fontBold pl-30 pr-30">
                                    {from === "forget" ? "Password successfully changed" : "Ready for a next-gen fan experience?"}
                                </H3>
                            </Section>
                        </Main>

                        <DefaultButton onClick={login} className="absolute bottom-20 left-20 right-20 w-auto">Continue</DefaultButton>
                    </>
                )}
            </SubContainer>
        </MainContainer>
    );
}

export default NewPassword;
