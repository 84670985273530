import React from "react";
import {clearUserData} from "../utils/authUtils"
import { useNavigate } from "react-router-dom";
import styled from "styled-components"
const Popup =styled.div `
position: absolute;
height: max-content;
width: max-content;
color: white;

padding: 10px;
right: 6%;
top: 65%;
background-color: var(--articlelarge-bg-color);
z-index: 1;
`;
const Items = styled.div`
cursor: pointer;
margin-top:5px;
`

function SettingsPopup(props) {
    const navigate = useNavigate();
    const handleLogout = () => {
        clearUserData()
        setTimeout(() => {
            props.openPopUp(false)
            navigate('/login')
        }, 100);
        
    }
    return (
        <Popup >
        <Items onClick={handleLogout}>Logout</Items>
        </Popup>
        
    )
}
export default SettingsPopup;